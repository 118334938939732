// // Fonts
// @import url('https://fonts.googleapis.com/css?family=Nunito');

// // Variables
// @import 'variables';

// // Bootstrap
// @import '~bootstrap/scss/bootstrap';

.btn-sort {
  line-height: 14px;
}
.btn-sort-up {
  padding-top: 8px;
  padding-bottom: 2px;
  float: left;
}
.btn-sort-down {
  padding-top: 2px;
  padding-bottom: 8px;
  float: right;
}
.btn-sort-group {
  width: 58px;
}
label.required:after {
  content: '*';
  color: red;
}
.form-cleaning,
.form-fishing {
  .col-form-label {
    width: 120px;
  }
}
.form-resident {
  .col-form-label {
    width: 120px;
  }
}
.form-tourist {
  .col-form-label {
    width: 160px;
  }
}
.form-pwd-change {
  .col-form-label {
    width: 160px;
  }
}
.mw-160 {
  max-width: 160px;
}
.announce-grid {
  display: grid;
  grid-auto-columns: 270px;
  overflow: auto;
  > div {
    position: relative;
    padding: 20px 15px 20px 40px;
    .parent-option {
      position: absolute;
      left: 4px;
      top: 52px;
      font-size: 14px;
      z-index: 1;
    }
    &:before {
      content: '';
      position: absolute;
      top: 75px;
      width: 40px;
      height: calc(100% - 75px);
      left: 0;
      border-top: 1px solid rgba(0, 0, 0, 0.2);
      border-left: 1px solid rgba(0, 0, 0, 0.2);
    }
    &:after {
      content: '';
      position: absolute;
      top: 75px;
      right: 0;
      width: 15px;
      border-top: 1px solid rgba(0, 0, 0, 0.2);
    }
    &:first-child {
      padding-left: 0;
      &:before {
        display: none !important;
      }
    }
    &.last-column:after {
      display: none;
    }
    &.last-row:before {
      border-left: none;
    }
    &.first-row .announce-item:before {
      display: none;
    }
    &.empty-area {
      border-left: 1px solid rgba(0, 0, 0, 0.2);
      &::after, &:before {
        display: none;
      }
    }
  }
}
.announce-item {
  position: relative;
  font-size: 15px;
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  h4 {
    font-size: 15px;
    font-weight: bold;
    margin-bottom: 0;
  }
  p {
    margin-bottom: 6px;
  }
  &:before {
    content: '';
    position: absolute;
    top: -21px;
    left: -41px;
    width: 40px;
    height: 75px;
    border-left: 1px solid rgba(0, 0, 0, 0.2);
  }
}
.fs-14 {
  font-size: 14px;
}
.fs-13 {
  font-size: 13px;
}
.between {
  display: flex;
  align-items: center;
  gap: 6px;
}
.broadcast-pagination .pagination {
  margin-bottom: 0;
}
.w-fix-800 {
  width: 800px;
}
.w-fix-120 {
  width: 120px;
}
.w-fix-140 {
  width: 140px;
}
.w-fix-160 {
  width: 160px;
}
.text-preline {
  white-space: pre-line !important;
}
.td-btn {
  padding: 8px 10px !important;
}
.table-no-style {
  th, td {
    border: none !important;
    padding: 0 !important;
  }
}
.w-100p {
  width: 100%; 
}
.flex-1 {
  flex: 1;
}
.tbl-cleaning-summary {
  max-width: 650px;
  td {
    min-width: 50px;
    padding: 6px 8px;
  }
}
.row-checks {
  display: flex;
  flex-wrap: wrap;
  column-gap: 10px;
  row-gap: 6px;
}
.column-gap-30 {
  column-gap: 30px;
}